import {HierarchicalMenuItem} from '../../../generic/hierarchical-menu/hierarchical-menu-types';

export function getBurgerMenuItems(): Readonly<HierarchicalMenuItem> {
  const menu: HierarchicalMenuItem = {
    title: 'Menu',
    children: [],
    divider: 'none',
    display: 'everyone'
  };

  menu.children.push({
    title: 'Dashboard',
    url: '/dashboard',
    parent: menu,
    children: [],
    divider: 'none',
    external: false,
    newTab: false,
    display: 'everyone'
  });
  menu.children.push({
    title: 'Beginner Journey',
    url: '/beginner-journey',
    parent: menu,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  menu.children.push({
    title: 'LiveClass',
    url: '/live-class',
    parent: menu,
    children: [],
    divider: 'none',
    external: false,
    newTab: false,
    display: 'loggedIn'
  });
  menu.children.push({
    title: 'Campus',
    url: 'https://campus.scottsbasslessons.com',
    parent: menu,
    children: [],
    divider: 'below',
    external: true,
    newTab: true,
    display: 'loggedIn'
  });
  menu.children.push({
    title: 'Courses',
    url: '/courses',
    parent: menu,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  menu.children.push({
    title: 'Players Path',
    url: '/players-path',
    parent: menu,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  menu.children.push({
    title: 'Learning Pathways',
    url: '/learning-pathways',
    parent: menu,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  menu.children.push({
    title: 'Mentors Live',
    url: '/mentors',
    parent: menu,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  menu.children.push({
    title: 'Student Focus',
    url: '/shows/student-focus',
    parent: menu,
    children: [],
    divider: 'below',
    display: 'everyone'
  });
  menu.children.push({
    title: 'Groove Trainer',
    url: '/groove-trainer',
    parent: menu,
    children: [],
    divider: 'below',
    display: 'everyone'
  });
  menu.children.push({
    title: 'Search SBL',
    url: '/search',
    parent: menu,
    children: [],
    divider: 'below',
    display: 'everyone'
  });

  const bassCulture: HierarchicalMenuItem = {
    title: 'Bass Culture',
    parent: menu,
    children: [],
    divider: 'below',
    display: 'everyone'
  };
  bassCulture.children.push({
    title: 'Blog',
    url: '/blog',
    parent: bassCulture,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  bassCulture.children.push({
    title: 'Podcasts',
    url: '/podcast',
    parent: bassCulture,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  bassCulture.children.push({
    title: 'Interviews',
    url: '/interviews',
    parent: bassCulture,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  bassCulture.children.push({
    title: 'Academy Show',
    url: '/shows/academy-show',
    parent: bassCulture,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  menu.children.push(bassCulture);

  const store: HierarchicalMenuItem = {
    title: 'Store',
    parent: menu,
    children: [],
    divider: 'below',
    display: 'everyone'
  };
  store.children.push({
    title: 'Member Discounts',
    url: '/member-discounts',
    parent: store,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  store.children.push({
    title: 'Shop',
    url: 'https://shop.scottsbasslessons.com/',
    parent: store,
    children: [],
    divider: 'none',
    external: true,
    newTab: true,
    display: 'everyone'
  });
  menu.children.push(store);

  const sblInfo: HierarchicalMenuItem = {
    title: 'SBL Information',
    parent: menu,
    children: [],
    divider: 'none',
    display: 'everyone'
  };
  sblInfo.children.push({
    title: 'Our Tutors',
    url: '/tutors',
    parent: sblInfo,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  sblInfo.children.push({
    title: 'Academy Tour',
    url: '/orientation',
    parent: sblInfo,
    children: [],
    divider: 'none',
    display: 'loggedIn'
  });
  sblInfo.children.push({
    title: 'About SBL',
    url: '/about',
    parent: sblInfo,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  sblInfo.children.push({
    title: 'Customer Support',
    url: '/contact',
    parent: sblInfo,
    children: [],
    divider: 'none',
    display: 'everyone'
  });
  menu.children.push(sblInfo);

  menu.children.push({
    title: 'Login',
    url: '/login',
    parent: menu,
    children: [],
    divider: 'above',
    addRedirectToUrl: true,
    display: 'guest'
  });

  return menu;
}
